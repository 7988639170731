import React, { useState } from 'react';
import Modal from './Modal';
import EditDocument from './EditDocument';

const ProjectDetailsTableauDocument = ({ project, details, searchQueryDoc, setSearchQueryDoc, formatDate, handleDownload, handleDeleteDocument, isEditModalOpen, currentDocument, openEditModal, closeEditModal, onEditSuccess }) => {
  const [hoveredColumns, setHoveredColumns] = useState(null);

  const handleMouseEnters = (index) => {
    setHoveredColumns(index);
  };

  const handleMouseLeaves = () => {
    setHoveredColumns(null);
  };

  return (
    <div>
      <h1 className="text-2xl font-bold text-gray-800 my-6">Tableau de documents</h1>
      <div className="mt-4">
        <input
          className="border p-2 rounded w-full mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
          type="text"
          placeholder="Rechercher par Nom du Document"
          value={searchQueryDoc}
          onChange={(e) => setSearchQueryDoc(e.target.value)}
        />
      </div>
      <div className="mt-6 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider">
                Nom du Projet
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider">
                Campagne
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider">
                Document
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {details.map((detail, index) => (
              detail.documents?.filter(doc => doc.nomDocument.toLowerCase().includes(searchQueryDoc.toLowerCase())).map((doc) => (
                <tr key={doc.id}
                  onMouseEnter={() => handleMouseEnters(index)}
                  onMouseLeave={handleMouseLeaves}
                >
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm font-bold">
                    {project.NomDuProjet}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm font-bold">
                    {formatDate(detail.Campagne)}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {decodeURIComponent(doc.nomDocument)}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm space-x-2">
                    <button
                      onClick={() => handleDownload(detail.id, doc.id, doc.nomDocument)}
                      className={`py-1 px-2 rounded ${hoveredColumns === index ? 'bg-green-600 text-white hover:bg-green-400' : 'bg-white text-white hover:text-white'}`}
                    >
                      Télécharger
                    </button>
                    <button
                      onClick={() => openEditModal(doc)}
                      className={`py-1 px-2 rounded ml-4 ${hoveredColumns === index ? 'bg-blue-600 text-white hover:bg-blue-400' : 'bg-white text-white hover:bg-green-400 hover:text-white'}`}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeleteDocument(detail.id, doc.id)}
                      className={`py-1 px-2 rounded ml-4 ${hoveredColumns === index ? 'bg-red-600 text-white hover:bg-red-400' : 'bg-white text-white hover:bg-green-400 hover:text-white'}`}
                    >
                      Supprimer
                    </button>
                  </td>
                </tr>
              ))
            ))}
          </tbody>
        </table>
      </div>
      {isEditModalOpen && currentDocument && (
        <Modal isOpen={isEditModalOpen} onClose={closeEditModal}>
          <EditDocument
            projectId={project.id}
            detailId={currentDocument.ID_DetailProjet}
            document={currentDocument}
            onEditSuccess={onEditSuccess}
          />
        </Modal>
      )}
    </div>
  );
};

export default ProjectDetailsTableauDocument;
