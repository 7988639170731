import React, { useState } from 'react';
import axios from 'axios';

const ProjectDetailsTableau = ({ details, projectId, searchQuery, setSearchQuery, fetchProjectDetails, toggleAddDocumentModal, toggleAddDetailModal, isModalOpen, handleDeleteDetail, formatDate, hoveredColumn, handleMouseEnter, handleMouseLeave, navigate }) => {
  const [editDetailId, setEditDetailId] = useState(null);
  const [editFormData, setEditFormData] = useState({
    Libelle: '',
    Commentaire: '',
    Campagne: ''
  });

  const filterDetails = () => {
    return details.filter((detail) => {
      return (
        detail.Libelle.toLowerCase().includes(searchQuery.toLowerCase()) ||
        detail.Commentaire.toLowerCase().includes(searchQuery.toLowerCase()) ||
        formatDate(detail.Campagne).includes(searchQuery)
      );
    });
  };

  const handleEditClick = (detail) => {
    setEditDetailId(detail.id);
    setEditFormData({
      Libelle: decodeURIComponent(detail.Libelle),
      Commentaire: decodeURIComponent(detail.Commentaire),
      Campagne: detail.Campagne.split('T')[0] // Assuming Campagne is an ISO date string
    });
  };

  const handleCancelClick = () => {
    setEditDetailId(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({
      ...editFormData,
      [name]: value
    });
  };

  const handleSaveClick = async () => {
    try {
      const token = localStorage.getItem('token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      await axios.put(`${apiBaseUrl}/projets/${projectId}/details/${editDetailId}`, editFormData, config);
      await fetchProjectDetails();
      setEditDetailId(null);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du détail:', error);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <input
          className="flex-grow border p-2 rounded mr-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
          type="text"
          placeholder="Rechercher par Libelle, Commentaire ou Campagne"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={toggleAddDetailModal} className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded text-2xl">
          <i className="fas fa-plus"></i>
        </button>
      </div>
      <div className="mt-6 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider">
                Campagne
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider">
                Libelle
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider">
                Commentaire
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {filterDetails().map((detail, index) => (
              <tr key={detail.id}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                {editDetailId === detail.id ? (
                  <>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm font-bold">
                      <input
                        type="date"
                        name="Campagne"
                        value={editFormData.Campagne}
                        onChange={handleInputChange}
                        className="border-2 border-gray-300 rounded-md w-full"
                      />
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm">
                      <input
                        type="text"
                        name="Libelle"
                        value={editFormData.Libelle}
                        onChange={handleInputChange}
                        className="border-2 border-gray-300 rounded-md w-full"
                      />
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm">
                      <input
                        type="text"
                        name="Commentaire"
                        value={editFormData.Commentaire}
                        onChange={handleInputChange}
                        className="border-2 border-gray-300 rounded-md w-full"
                      />
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm space-x-2">
                      <button
                        onClick={handleSaveClick}
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded mr-2"
                      >
                        Enregistrer
                      </button>
                      <button
                        onClick={handleCancelClick}
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded"
                      >
                        Annuler
                      </button>
                    </td>
                  </>
                ) : (
                  <>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm font-bold">
                      {formatDate(detail.Campagne)}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm">
                      {decodeURIComponent(detail.Libelle)}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm">
                      {decodeURIComponent(detail.Commentaire)}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm space-x-2">
                      <button
                        onClick={() => navigate(`/projets/${projectId}/details/${detail.id}/dashboards`)}
                        className={`py-1 px-2 rounded ${hoveredColumn === index ? 'bg-green-600 text-white hover:bg-green-400' : 'bg-white text-white hover:text-white'}`}
                      >
                        Dashboards
                      </button>
                      <button
                        onClick={() => toggleAddDocumentModal(detail.id)}
                        className={`py-1 px-2 rounded ml-4 ${hoveredColumn === index ? 'bg-green-600 text-white hover:bg-green-400' : 'bg-white text-white hover:bg-green-400 hover:text-white'}`}
                      >
                        Ajouter un document
                      </button>
                      <button
                        onClick={() => handleEditClick(detail)}
                        className={`py-1 px-2 rounded ml-4 ${hoveredColumn === index ? 'bg-blue-600 text-white hover:bg-blue-400' : 'bg-white text-white hover:bg-green-400 hover:text-white'}`}
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDeleteDetail(detail.id)}
                        className={`py-1 px-2 rounded ml-4 ${hoveredColumn === index ? 'bg-red-600 text-white hover:bg-red-400' : 'bg-white text-white hover:bg-green-400 hover:text-white'}`}
                      >
                        Supprimer
                      </button>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProjectDetailsTableau;
