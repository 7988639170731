// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-login {
    width: 60%; /* Définit la largeur à 60% */
  }

  .active-link {
    background-color: #ccffcc; /* Remplacer par la valeur hexadécimale de green-200 */
    color: black; /* Remplacer par la valeur hexadécimale de green-600 */
  }
  
  .sidebar-nav {
    max-height: calc(100vh - 4rem); /* Adjust based on your layout */
    overflow-y: auto;
  }

  .sidebar-nav ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .sidebar-nav li {
    margin: 0;
  }`, "",{"version":3,"sources":["webpack://./src/css/Login.css"],"names":[],"mappings":"AAAA;IACI,UAAU,EAAE,6BAA6B;EAC3C;;EAEA;IACE,yBAAyB,EAAE,sDAAsD;IACjF,YAAY,EAAE,sDAAsD;EACtE;;EAEA;IACE,8BAA8B,EAAE,gCAAgC;IAChE,gBAAgB;EAClB;;EAEA;IACE,UAAU;IACV,SAAS;IACT,qBAAqB;EACvB;;EAEA;IACE,SAAS;EACX","sourcesContent":[".btn-login {\n    width: 60%; /* Définit la largeur à 60% */\n  }\n\n  .active-link {\n    background-color: #ccffcc; /* Remplacer par la valeur hexadécimale de green-200 */\n    color: black; /* Remplacer par la valeur hexadécimale de green-600 */\n  }\n  \n  .sidebar-nav {\n    max-height: calc(100vh - 4rem); /* Adjust based on your layout */\n    overflow-y: auto;\n  }\n\n  .sidebar-nav ul {\n    padding: 0;\n    margin: 0;\n    list-style-type: none;\n  }\n\n  .sidebar-nav li {\n    margin: 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
