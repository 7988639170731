import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useProjects } from '../ProjectContext';
import { useCampaigns } from '../CampaignContext';
import '../css/Login.css';
import _ from 'lodash';

const Sidebar = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { projects } = useProjects();
  const { campaigns, fetchCampaigns } = useCampaigns();
  const [expandedProjects, setExpandedProjects] = useState({});
  const [projectsMenuExpanded, setProjectsMenuExpanded] = useState(false);

  useEffect(() => {
    const projectIdMatch = location.pathname.match(/\/project-details\/(\d+)/);
    const campaignIdMatch = location.pathname.match(/\/projets\/(\d+)\/details\/(\d+)\/dashboards/);

    if (projectIdMatch || campaignIdMatch) {
      const projectId = projectIdMatch ? projectIdMatch[1] : campaignIdMatch[1];
      setProjectsMenuExpanded(true);
      setExpandedProjects(prev => ({ ...prev, [projectId]: true }));
      fetchCampaigns(projectId);
    }
  }, [location.pathname, fetchCampaigns]);

  const toggleDetails = useCallback(async (projectId, e) => {
    e.stopPropagation();
    if (expandedProjects[projectId]) {
      setExpandedProjects(prev => ({ ...prev, [projectId]: false }));
    } else {
      setExpandedProjects(prev => ({ ...prev, [projectId]: true }));
      await fetchCampaigns(projectId);
    }
  }, [expandedProjects, fetchCampaigns]);

  const debouncedToggleDetails = useMemo(
    () => _.debounce(toggleDetails, 300),
    [toggleDetails]
  );

  const toggleProjectsMenu = () => {
    setProjectsMenuExpanded(prev => !prev);
  };

  const handleProjectsClick = () => {
    navigate('/view-projects');
  };

  const handleProjectClick = (projectId) => {
    navigate(`/project-details/${projectId}`);
  };

  const handleCampaignClick = (projectId, detailId) => {
    navigate(`/projets/${projectId}/details/${detailId}/dashboards`);
  };

  const sortedProjects = useMemo(() => {
    return projects ? [...projects].sort((a, b) => a.NomDuProjet.localeCompare(b.NomDuProjet)) : [];
  }, [projects]);

  if (!user) return null;

  return (
    <div className="relative min-h-screen pt-14">
      <aside className="w-48 h-full shadow-md bg-white fixed top-14 left-0 flex flex-col justify-between z-40 overflow-y-auto">
        <div>
          <div className="px-4 py-6 border-b border-gray-200 flex items-center justify-center">
          </div>
          <nav className="sidebar-nav">
            <ul className="space-y-2">
              <li>
                <div className={`w-full text-left px-4 py-2 flex items-center justify-between text-gray-700 hover:bg-green-300 rounded-md font-bold cursor-pointer ${location.pathname === '/view-projects' ? 'bg-green-200' : ''}`}
                     onClick={handleProjectsClick}>
                  <i className={`fas ${projectsMenuExpanded ? 'fa-chevron-up' : 'fa-chevron-down'} mr-2 cursor-pointer`} onClick={toggleProjectsMenu}></i>
                  <span className="flex-grow text-sm">Projets</span>
                </div>
              </li>
              {projectsMenuExpanded && sortedProjects.map(project => (
                <li key={project.id} className="hover:shadow-lg">
                  <div
                    className={`w-full text-left px-6 py-2 flex items-center justify-between text-gray-700 hover:bg-green-200 rounded-md font-bold cursor-pointer ${location.pathname.includes(`/project-details/${project.id}`) ? 'bg-green-200' : ''}`}
                    onClick={() => handleProjectClick(project.id)}
                  >
                    <i
                      onClick={(e) => debouncedToggleDetails(project.id, e)}
                      className={`fas ${expandedProjects[project.id] ? 'fa-chevron-up' : 'fa-chevron-down'} mr-2 cursor-pointer`}
                    ></i>
                    <span className="flex-grow text-sm">{project.NomDuProjet}</span>
                  </div>

                  {expandedProjects[project.id] && Array.isArray(campaigns[project.id]) && (
                    <ul className="space-y-2 pl-2">
                      {campaigns[project.id].map(detail => (
                        <li key={detail.id} className="hover:text-blue-800">
                          <div
                            className={`w-full text-left px-8 py-2 flex items-center justify-between text-gray-700 hover:bg-green-200 rounded-md font-medium cursor-pointer ${location.pathname.includes(`/projets/${project.id}/details/${detail.id}/dashboards`) ? 'bg-green-200' : ''}`}
                            onClick={() => handleCampaignClick(project.id, detail.id)}
                          >
                            <span className="text-xs">{new Date(detail.Campagne).toLocaleDateString()}</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default React.memo(Sidebar);
