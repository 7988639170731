import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const EditDashboard = ({ dashboard, onEditSuccess, onClose }) => {
  const { id, detailId } = useParams(); // ID du projet et ID du détail
  const [dashboardData, setDashboardData] = useState({ ...dashboard });
  const [message, setMessage] = useState('');

  useEffect(() => {
    setDashboardData({ ...dashboard });
  }, [dashboard]);

  const handleChange = (e) => {
    setDashboardData({ ...dashboardData, [e.target.name]: e.target.value });
    setMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      await axios.put(`${apiBaseUrl}/projets/${id}/details/${detailId}/dashboards/${dashboard.id}`, dashboardData, config);
      setMessage('Dashboard modifié avec succès.');
      if (onEditSuccess) {
        onEditSuccess();
      }
      onClose();
    } catch (error) {
      console.error('Erreur lors de la modification du dashboard:', error);
      setMessage('Erreur lors de la modification. Veuillez réessayer.');
    }
  };

  return (
    <div className="max-w-md mx-auto my-4 p-4 bg-white shadow-md rounded">
      <h2 className="text-lg font-semibold text-gray-800 mb-4">Modifier le Dashboard</h2>
      {message && (
        <div className={message.includes('Erreur') ? "bg-red-500 text-white p-2 rounded" : "bg-green-500 text-white p-2 rounded"}>
          {message}
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Nom:</label>
          <input 
            type="text" 
            name="nom" 
            value={dashboardData.nom} 
            onChange={handleChange} 
            required 
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">URL du Dashboard:</label>
          <input 
            type="url" 
            name="url_Dashboard" 
            value={dashboardData.url_Dashboard} 
            onChange={handleChange} 
            required 
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Type de Dashboard:</label>
          <select 
            name="TypeDashboard" 
            value={dashboardData.TypeDashboard} 
            onChange={handleChange} 
            required 
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="Synthetic Monitoring">Synthetic Monitoring</option>
            <option value="Infrastructure Endpoint">Infrastructure Endpoint</option>
            <option value="Endpoint Application">Endpoint Application</option>
            <option value="Digital Journey">Digital Journey</option>
            <option value="Digital Experience Monitoring">Digital Experience Monitoring</option>
            <option value="Load Test">Load Test</option>
            <option value="Autre">Autre</option>
          </select>
        </div>
        <div className="flex justify-end">
          <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Modifier</button>
        </div>
      </form>
    </div>
  );
};

export default EditDashboard;
