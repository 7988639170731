import React, { useState } from 'react';
import axios from 'axios';

const EditDocument = ({ projectId, detailId, document, onEditSuccess }) => {
  const [nomDocument, setNomDocument] = useState(document.nomDocument);
  const [file, setFile] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleNomChange = (e) => {
    setNomDocument(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    setError('');

    const formData = new FormData();
    formData.append('nomDocument', nomDocument);  // Mettre à jour le nom du document même sans changement de fichier
    if (file) {
      formData.append('file', file);
    }

    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      };
      const apiBaseUrl = process.env.REACT_APP_API_URL || ''; 
      const response = await axios.put(`${apiBaseUrl}/projets/${projectId}/details/${detailId}/documents/${document.id}`, formData, config);

      onEditSuccess(response.data, detailId);  // Assurez-vous de passer le detailId si nécessaire
      setIsUpdating(false);
    } catch (error) {
      setError("Une erreur s'est produite lors de la mise à jour du document.");
      setIsUpdating(false);
    }
  };

  return (
    <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-lg">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="nomDocument" className="block text-sm font-medium text-gray-700">Nom du Document:</label>
          <input
            type="text"
            value={nomDocument}
            onChange={handleNomChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Document:</label>
          <input
            type="file"
            onChange={handleFileChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <button
          type="submit"
          disabled={isUpdating}
          className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${isUpdating ? 'bg-gray-500' : 'bg-indigo-600 hover:bg-indigo-700'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
        >
          Mettre à jour
        </button>
      </form>
      {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">{error}</div>}
    </div>
  );
};

export default EditDocument;
