import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../AuthContext';

const UserList = () => {
  const { user } = useAuth();
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');
  const [editUserId, setEditUserId] = useState(null);
  const [editFormData, setEditFormData] = useState({
    nom: '',
    prenom: '',
    email: '',
    role: '',
    numero: ''
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token');
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const apiBaseUrl = process.env.REACT_APP_API_URL || '';
        const response = await axios.get(`${apiBaseUrl}/users`, config);
        const sortedUsers = response.data.sort((a, b) => a.prenom.localeCompare(b.prenom));
        setUsers(sortedUsers);
      } catch (err) {
        setError('Erreur lors de la récupération des utilisateurs.');
      }
    };

    if (user && user.role === 'ADMIN') {
      fetchUsers();
    }
  }, [user]);

  const handleDelete = async (userId) => {
    if (user.id === userId) {
      setError('Vous ne pouvez pas vous supprimer vous-même.');
      clearErrorAfterTimeout();
      return;
    }

    const confirmation = window.confirm('Êtes-vous sûr de vouloir supprimer cet utilisateur ?');
    if (!confirmation) {
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      await axios.delete(`${apiBaseUrl}/users/${userId}`, config);
      setUsers(users.filter(user => user.id !== userId));
    } catch (error) {
      setError('Erreur lors de la suppression de l\'utilisateur. Veuillez réessayer.');
    }
  };

  const handleEditClick = (user) => {
    setEditUserId(user.id);
    setEditFormData(user);
  };

  const handleCancelClick = () => {
    setEditUserId(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'numero') {
      const onlyNums = value.replace(/[^0-9]/g, '');
      setEditFormData({ ...editFormData, [name]: onlyNums });
    } else {
      setEditFormData({ ...editFormData, [name]: value });
    }
  };

  const handleSaveClick = async () => {
    try {
      const token = localStorage.getItem('token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      
      // Update user profile without role
      await axios.put(`${apiBaseUrl}/users/${editUserId}`, {
        nom: editFormData.nom,
        prenom: editFormData.prenom,
        email: editFormData.email,
        numero: editFormData.numero,
      }, config);
      
      // Update user role separately
      await axios.put(`${apiBaseUrl}/users/${editUserId}/role`, {
        role: editFormData.role,
      }, config);

      const response = await axios.get(`${apiBaseUrl}/users`, config); // Fetch the updated user list
      const sortedUsers = response.data.sort((a, b) => a.prenom.localeCompare(b.prenom));
      setUsers(sortedUsers);
      setEditUserId(null);
    } catch (error) {
      setError('Erreur lors de la mise à jour de l\'utilisateur. Veuillez réessayer.');
    }
  };

  const clearErrorAfterTimeout = () => {
    setTimeout(() => {
      setError('');
    }, 5000);
  };

  if (!user || user.role !== 'ADMIN') {
    return <p>Accès refusé. Vous n'avez pas les autorisations nécessaires pour voir cette page.</p>;
  }

  return (
    <div className="table-responsive" style={{ overflowX: 'auto' }}>
      <h1 className="text-2xl font-bold mb-4">Liste des utilisateurs</h1>
      {error && <p className="text-red-500">{error}</p>}
      <table className="min-w-full divide-y divide-gray-200 table" style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead className="thead-light" style={{ backgroundColor: '#f8f9fa', borderBottom: '2px solid #dee2e6' }}>
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" style={{ padding: '0.75rem', verticalAlign: 'top', borderTop: '1px solid #dee2e6' }}>Prénom</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" style={{ padding: '0.75rem', verticalAlign: 'top', borderTop: '1px solid #dee2e6' }}>Nom</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" style={{ padding: '0.75rem', verticalAlign: 'top', borderTop: '1px solid #dee2e6' }}>Email</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" style={{ padding: '0.75rem', verticalAlign: 'top', borderTop: '1px solid #dee2e6' }}>Rôle</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" style={{ padding: '0.75rem', verticalAlign: 'top', borderTop: '1px solid #dee2e6' }}>Numéro</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" style={{ padding: '0.75rem', verticalAlign: 'top', borderTop: '1px solid #dee2e6' }}>Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {users.map((user) => (
            <tr key={user.id}>
              {editUserId === user.id ? (
                <>
                  <td className="px-6 py-4 whitespace-nowrap" style={{ padding: '0.75rem', verticalAlign: 'top', borderTop: '1px solid #dee2e6' }}>
                    <input
                      type="text"
                      name="prenom"
                      value={editFormData.prenom}
                      onChange={handleInputChange}
                      className="border-2 border-gray-300 rounded-md w-full"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap" style={{ padding: '0.75rem', verticalAlign: 'top', borderTop: '1px solid #dee2e6' }}>
                    <input
                      type="text"
                      name="nom"
                      value={editFormData.nom}
                      onChange={handleInputChange}
                      className="border-2 border-gray-300 rounded-md w-full"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap" style={{ padding: '0.75rem', verticalAlign: 'top', borderTop: '1px solid #dee2e6' }}>
                    <input
                      type="email"
                      name="email"
                      value={editFormData.email}
                      onChange={handleInputChange}
                      className="border-2 border-gray-300 rounded-md w-full"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap" style={{ padding: '0.75rem', verticalAlign: 'top', borderTop: '1px solid #dee2e6' }}>
                    <select
                      name="role"
                      value={editFormData.role}
                      onChange={handleInputChange}
                      className="border-2 border-gray-300 rounded-md w-full"
                    >
                      <option value="USER">USER</option>
                      <option value="ADMIN">ADMIN</option>
                    </select>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap" style={{ padding: '0.75rem', verticalAlign: 'top', borderTop: '1px solid #dee2e6' }}>
                    <input
                      type="text"
                      name="numero"
                      value={editFormData.numero}
                      onChange={handleInputChange}
                      pattern="\d*"
                      className="border-2 border-gray-300 rounded-md w-full"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap" style={{ padding: '0.75rem', verticalAlign: 'top', borderTop: '1px solid #dee2e6' }}>
                    <button
                      onClick={handleSaveClick}
                      className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded mr-2"
                    >
                      Enregistrer
                    </button>
                    <button
                      onClick={handleCancelClick}
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded"
                    >
                      Annuler
                    </button>
                  </td>
                </>
              ) : (
                <>
                  <td className="px-6 py-4 whitespace-nowrap" style={{ padding: '0.75rem', verticalAlign: 'top', borderTop: '1px solid #dee2e6' }}>{user.prenom}</td>
                  <td className="px-6 py-4 whitespace-nowrap" style={{ padding: '0.75rem', verticalAlign: 'top', borderTop: '1px solid #dee2e6' }}>{user.nom}</td>
                  <td className="px-6 py-4 whitespace-nowrap" style={{ padding: '0.75rem', verticalAlign: 'top', borderTop: '1px solid #dee2e6' }}>{user.email}</td>
                  <td className="px-6 py-4 whitespace-nowrap" style={{ padding: '0.75rem', verticalAlign: 'top', borderTop: '1px solid #dee2e6' }}>{user.role}</td>
                  <td className="px-6 py-4 whitespace-nowrap" style={{ padding: '0.75rem', verticalAlign: 'top', borderTop: '1px solid #dee2e6' }}>{user.numero}</td>
                  <td className="px-6 py-4 whitespace-nowrap" style={{ padding: '0.75rem', verticalAlign: 'top', borderTop: '1px solid #dee2e6' }}>
                    <button
                      onClick={() => handleEditClick(user)}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mr-2"
                    >
                      Editer
                    </button>
                    <button
                      onClick={() => handleDelete(user.id)}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                    >
                      Supprimer
                    </button>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserList;
