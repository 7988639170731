import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useProjects } from '../ProjectContext';

const CreateProject = ({ onClose }) => {
  const [formData, setFormData] = useState({
    NomDuProjet: '',
    Titre: '',
    Commentaire: ''
  });
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { user } = useAuth();
  const { addProject } = useProjects();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.NomDuProjet.trim() || !formData.Titre.trim()) {
      setMessage('Les champs Nom du Projet et Titre sont requis.');
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      const response = await axios.post(`${apiBaseUrl}/projets`, formData, config);
      const newProject = response.data;
  
      setMessage('Projet créé avec succès.');
      setFormData({ NomDuProjet: '', Titre: '', Commentaire: '' });
      addProject(newProject);
      navigate('/view-projects');
      onClose();
    } catch (error) {
      if (error.response) {
        setMessage('Erreur lors de la création du projet. Veuillez réessayer.');
      } else {
        setMessage('Erreur lors de la création du projet. Veuillez réessayer.');
      }
    }
  };

  return (
    <div className="max-w-xl mx-auto p-4">
      <h2 className="text-lg font-semibold text-gray-800">Bienvenue sur PowerHouse, {user.nom}</h2>
      <h2 className="text-lg font-semibold text-gray-800 mb-4">Créer un nouveau projet</h2>
      {message && (
        <div className={message.includes('Erreur') ? "bg-red-500 text-white p-2 rounded" : "bg-green-500 text-white p-2 rounded"}>
          {message}
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">Nom du Projet:</label>
          <input 
            type="text"
            name="NomDuProjet"
            value={formData.NomDuProjet}
            onChange={handleChange}
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Titre:</label>
          <input 
            type="text"
            name="Titre"
            value={formData.Titre}
            onChange={handleChange}
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Commentaire:</label>
          <textarea 
            name="Commentaire"
            value={formData.Commentaire}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          ></textarea>
        </div>
        <div className="flex justify-between">
          <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            Créer
          </button>
          <button onClick={onClose} type="button" className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            Annuler
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateProject;
