// src/authContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

// Créer un contexte d'authentification
const AuthContext = createContext();

// Créer un composant de fournisseur d'authentification
const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fonction pour se connecter
  const login = async (token) => {
    try {
      localStorage.setItem('token', token);
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      const response = await axios.get(`${apiBaseUrl}/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setUser(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des informations utilisateur:', error);
      setLoading(false); // Assurez-vous que loading est mis à jour en cas d'erreur
    }
  };

  // Fonction pour se déconnecter
  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  // Charger les informations de l'utilisateur à partir du token lors du chargement de la page
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      (async () => {
        await login(token);
        setLoading(false);
      })();
    } else {
      setLoading(false);
    }
  }, []);

  const contextValue = {
    user,
    login,
    logout,
    loading,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

// Créer un hook personnalisé pour utiliser le contexte d'authentification
const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth doit être utilisé à l'intérieur d'un AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
