import React, { useState } from 'react';
import axios from 'axios';
import UserList from './UserList'; 

const AdminPanel = () => {
  const [selectedTab, setSelectedTab] = useState('addUser');
  const [newUserData, setNewUserData] = useState({
    nom: '',
    prenom: '',
    email: '',
    motDePasse: '',
    confirmerMotDePasse: '',
    role: 'USER',
    numero: ''
  });
  const [showPassword, setShowPassword] = useState({
    motDePasse: false,
    confirmerMotDePasse: false
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    setError('');
    setSuccessMessage('');
  };

  const handleNewUserChange = (e) => {
    const { name, value } = e.target;
    if (name === 'numero') {
      const onlyNums = value.replace(/[^0-9]/g, '');
      setNewUserData({ ...newUserData, [name]: onlyNums });
    } else {
      setNewUserData({ ...newUserData, [name]: value });
    }
  };

  const toggleShowPassword = (field) => {
    setShowPassword({ ...showPassword, [field]: !showPassword[field] });
  };

  const createUser = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    if (!newUserData.nom || !newUserData.prenom || !newUserData.email || !newUserData.motDePasse || !newUserData.confirmerMotDePasse || !newUserData.role) {
      setError('Tous les champs sont requis.');
      clearErrorAfterTimeout();
      return;
    }
    if (newUserData.motDePasse.length < 6) {
      setError('Le mot de passe doit contenir au moins 6 caractères.');
      clearErrorAfterTimeout();
      return;
    }
    if (newUserData.motDePasse !== newUserData.confirmerMotDePasse) {
      setError('Les mots de passe ne correspondent pas.');
      clearErrorAfterTimeout();
      return;
    }
    try {
      const token = localStorage.getItem('token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      await axios.post(`${apiBaseUrl}/users/register`, newUserData, config);
      setSuccessMessage('Utilisateur créé avec succès.');
      alert('Utilisateur créé avec succès.');
      setNewUserData({
        nom: '',
        prenom: '',
        email: '',
        motDePasse: '',
        confirmerMotDePasse: '',
        role: 'USER',
        numero: ''
      });
      clearErrorAfterTimeout();
    } catch (error) {
      console.error('Erreur lors de la création de l\'utilisateur:', error);
      if (error.response && error.response.data && error.response.data.errors) {
        setError(error.response.data.errors.map(err => err.msg).join(', '));
      } else {
        setError('Erreur lors de la création de l\'utilisateur. Veuillez réessayer.');
      }
      clearErrorAfterTimeout();
    }
  };

  const clearErrorAfterTimeout = () => {
    setTimeout(() => {
      setError('');
      setSuccessMessage('');
    }, 5000);
  };

  return (
    <div className="flex flex-row min-h-screen">
      <div className="flex-grow" style={{ backgroundColor: '#F5F5F5' }}>
        <div className="w-full pt-6" style={{ height: '25vh', backgroundColor: '#166534' }}>
          <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 mt-20">
            <div className="bg-white p-6 shadow rounded-lg mt-8">
              <div className="pb-4">
                <h1 className="text-2xl font-bold">Gestion Administrateur</h1>
              </div>
              <div className="border-b border-gray-300 my-4">
                <nav className="flex space-x-4">
                  <button
                    className={`py-2 px-4 ${selectedTab === 'addUser' ? 'border-b-2 border-blue-500' : ''}`}
                    onClick={() => handleTabChange('addUser')}
                  >
                    Ajouter un Utilisateur
                  </button>
                  <button
                    className={`py-2 px-4 ${selectedTab === 'userList' ? 'border-b-2 border-blue-500' : ''}`}
                    onClick={() => handleTabChange('userList')}
                  >
                    Liste des Utilisateurs
                  </button>
                </nav>
              </div>
              <div className="flex-grow p-6 bg-white">
                <div className="max-w-6xl mx-auto bg-white p-6 shadow-2xl rounded-lg">
                  {selectedTab === 'addUser' && (
                    <>
                      <div className="border-b border-gray-300 pb-4 mb-4">
                        <h2 className="text-xl font-semibold mb-4">Créer un utilisateur</h2>
                      </div>
                      {error && <div className="bg-red-500 text-white p-2 rounded">{error}</div>}
                      {successMessage && <div className="bg-green-500 text-white p-2 rounded">{successMessage}</div>}
                      <form onSubmit={createUser}>
                        <div className="mb-4">
                          <label className="block text-gray-700 text-sm font-bold mb-2">Nom :</label>
                          <input
                            type="text"
                            name="nom"
                            value={newUserData.nom}
                            onChange={handleNewUserChange}
                            placeholder="Dupont"
                            autoComplete="off"
                            required
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block text-gray-700 text-sm font-bold mb-2">Prénom :</label>
                          <input
                            type="text"
                            name="prenom"
                            value={newUserData.prenom}
                            onChange={handleNewUserChange}
                            placeholder="Jean"
                            autoComplete="off"
                            required
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block text-gray-700 text-sm font-bold mb-2">Email :</label>
                          <input
                            type="email"
                            name="email"
                            value={newUserData.email}
                            onChange={handleNewUserChange}
                            placeholder="jean.dupont@example.com"
                            autoComplete="off"
                            required
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="mb-4 relative">
                          <label className="block text-gray-700 text-sm font-bold mb-2">Mot de passe :</label>
                          <input
                            type={showPassword.motDePasse ? 'text' : 'password'}
                            name="motDePasse"
                            value={newUserData.motDePasse}
                            onChange={handleNewUserChange}
                            placeholder="********"
                            autoComplete="off"
                            required
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          <i
                            className={`fas ${showPassword.motDePasse ? 'fa-eye-slash' : 'fa-eye'} absolute right-3 top-10 cursor-pointer text-gray-500`}
                            onClick={() => toggleShowPassword('motDePasse')}
                          ></i>
                        </div>
                        <div className="mb-4 relative">
                          <label className="block text-gray-700 text-sm font-bold mb-2">Confirmer le mot de passe :</label>
                          <input
                            type={showPassword.confirmerMotDePasse ? 'text' : 'password'}
                            name="confirmerMotDePasse"
                            value={newUserData.confirmerMotDePasse}
                            onChange={handleNewUserChange}
                            placeholder="********"
                            autoComplete="off"
                            required
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          <i
                            className={`fas ${showPassword.confirmerMotDePasse ? 'fa-eye-slash' : 'fa-eye'} absolute right-3 top-10 cursor-pointer text-gray-500`}
                            onClick={() => toggleShowPassword('confirmerMotDePasse')}
                          ></i>
                        </div>
                        <div className="mb-4">
                          <label className="block text-gray-700 text-sm font-bold mb-2">Role :</label>
                          <select
                            name="role"
                            value={newUserData.role}
                            onChange={handleNewUserChange}
                            required
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          >
                            <option value="USER">Utilisateur</option>
                            <option value="ADMIN">Admin</option>
                          </select>
                        </div>
                        <div className="mb-4">
                          <label className="block text-gray-700 text-sm font-bold mb-2">Numéro :</label>
                          <input
                            type="text"
                            name="numero"
                            value={newUserData.numero}
                            onChange={handleNewUserChange}
                            placeholder="0123456789"
                            autoComplete="off"
                            pattern="\d*"
                            required
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <button
                          type="submit"
                          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center focus:outline-none focus:shadow-outline"
                        >
                          <i className="fas fa-check mr-2"></i> Créer
                        </button>
                      </form>
                    </>
                  )}
                  {selectedTab === 'userList' && <UserList />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
