import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useProjects } from '../ProjectContext';
import Modal from './Modal';
import CreateProject from './CreateProject';
import axios from 'axios';
import _ from 'lodash';

const ViewProjects = () => {
  const { user } = useAuth();
  const { projects, fetchProjects } = useProjects();
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [hoveredColumn, setHoveredColumn] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [filteredUser, setFilteredUser] = useState(null);
  const [editProjectId, setEditProjectId] = useState(null);
  const [editFormData, setEditFormData] = useState({
    NomDuProjet: '',
    Titre: '',
    Commentaire: ''
  });

  const fetchUsers = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      const response = await axios.get(`${apiBaseUrl}/users`, config);
      setUsers(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des utilisateurs:', error);
    }
  }, []);

  useEffect(() => {
    if (user.role === 'ADMIN') {
      fetchUsers();
    }
  }, [user.role, fetchUsers]);

  useEffect(() => {
    const fetchFilteredProjects = async () => {
      if (selectedUserId === null) {
        await fetchProjects();
        setFilteredUser(null);
      } else {
        await fetchProjects(selectedUserId);
        const user = users.find(u => u.id === parseInt(selectedUserId));
        setFilteredUser(user);
      }
    };

    fetchFilteredProjects();
  }, [selectedUserId, fetchProjects, users]);

  const toggleModal = useCallback(() => {
    setIsModalOpen(prev => !prev);
  }, []);

  const toggleFilterModal = useCallback(() => {
    setIsFilterModalOpen(prev => !prev);
  }, []);

  const handleProjectAdd = useCallback(() => {
    fetchProjects(selectedUserId);
    toggleModal();
  }, [fetchProjects, toggleModal, selectedUserId]);

  const handleSearchChange = useMemo(
    () => _.debounce((event) => {
      setSearchTerm(event.target.value);
    }, 300),
    []
  );

  const handleUserChange = useCallback((event) => {
    const userId = event.target.value;
    setSelectedUserId(userId === 'all' ? null : userId);
    setIsFilterModalOpen(false);
  }, []);

  const handleMouseEnter = useCallback((index) => {
    setHoveredColumn(index);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoveredColumn(null);
  }, []);

  const handleDelete = useCallback(async (projectId) => {
    try {
      const token = localStorage.getItem('token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      await axios.delete(`${apiBaseUrl}/projets/${projectId}`, config);
      fetchProjects(selectedUserId);
    } catch (error) {
      setError('Erreur lors de la suppression du projet. Veuillez réessayer.');
    }
  }, [fetchProjects, selectedUserId]);

  const handleUnassign = useCallback(async (projectId, userIdToUnassign) => {
    try {
      const token = localStorage.getItem('token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      await axios.delete(`${apiBaseUrl}/projets/unassign/${projectId}/${userIdToUnassign}`, config);
      fetchProjects(selectedUserId);
    } catch (error) {
      setError('Erreur lors de la dissociation du projet. Veuillez réessayer.');
    }
  }, [fetchProjects, selectedUserId]);

  const confirmDelete = useCallback((projectId, projectName) => {
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer le projet "${projectName}" ?`)) {
      handleDelete(projectId);
    }
  }, [handleDelete]);

  const confirmUnassign = useCallback((projectId, projectName, userIdToUnassign) => {
    if (window.confirm(`Êtes-vous sûr de vouloir dissocier l'utilisateur du projet "${projectName}" ?`)) {
      handleUnassign(projectId, userIdToUnassign);
    }
  }, [handleUnassign]);

  const toggleAssignModal = useCallback(() => {
    setIsAssignModalOpen(prev => !prev);
  }, []);

  const openAssignModal = useCallback((projectId) => {
    setSelectedProjectId(projectId);
    toggleAssignModal();
  }, [toggleAssignModal]);

  const assignProjectToUser = useCallback(async (userId) => {
    if (!userId || !selectedProjectId) return;

    const selectedUser = users.find(u => u.id === parseInt(userId));
    const project = projects.find(p => p.id === selectedProjectId);

    if (project && project.users && project.users.some(u => u.id === parseInt(userId))) {
      alert(`L'utilisateur ${selectedUser.prenom} ${selectedUser.nom} est déjà assigné à ce projet.`);
      return;
    }

    if (window.confirm(`Êtes-vous sûr de vouloir assigner le projet à l'utilisateur ${selectedUser.prenom} ${selectedUser.nom} ?`)) {
      try {
        const token = localStorage.getItem('token');
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const apiBaseUrl = process.env.REACT_APP_API_URL || '';
        await axios.post(`${apiBaseUrl}/projets/assign/${selectedProjectId}`, { userId }, config);
        toggleAssignModal();
        fetchProjects(selectedUserId);
      } catch (error) {
        setError('Erreur lors de l’assignation du projet.');
        console.error(error);
      }
    }
  }, [selectedProjectId, fetchProjects, toggleAssignModal, selectedUserId, users, projects]);

  const handleUserChangeForAssignment = useCallback((event) => {
    assignProjectToUser(event.target.value);
  }, [assignProjectToUser]);

  const sortedProjects = useMemo(() => {
    return [...projects].sort((a, b) => a.NomDuProjet.localeCompare(b.NomDuProjet));
  }, [projects]);

  const filteredProjects = useMemo(() => {
    return searchTerm
      ? sortedProjects.filter(p =>
          p.NomDuProjet.toLowerCase().includes(searchTerm.toLowerCase()) ||
          p.Titre.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : sortedProjects;
  }, [searchTerm, sortedProjects]);

  // Handle edit button click
  const handleEditClick = (project) => {
    setEditProjectId(project.id);
    setEditFormData({
      NomDuProjet: project.NomDuProjet,
      Titre: project.Titre,
      Commentaire: project.Commentaire
    });
  };

  // Handle cancel edit
  const handleCancelClick = () => {
    setEditProjectId(null);
  };

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({
      ...editFormData,
      [name]: value
    });
  };

  // Handle save changes
  const handleSaveClick = async () => {
    try {
      const token = localStorage.getItem('token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      await axios.put(`${apiBaseUrl}/projets/${editProjectId}`, editFormData, config);
      await fetchProjects(selectedUserId);
      setEditProjectId(null);
    } catch (error) {
      setError('Erreur lors de la mise à jour du projet. Veuillez réessayer.');
    }
  };

  if (!user) return null;

  return (
    <div className="flex flex-row min-h-screen">
      <div className="flex-grow" style={{ backgroundColor: '#F5F5F5' }}>
        <div className="w-full pt-6" style={{ height: '25vh', backgroundColor: '#166534' }}>
          <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 mt-20">
            <div className="bg-white p-6 shadow rounded-lg mt-8">
              <h1 className="text-2xl font-bold text-gray-800 my-6">
                {user.role === 'ADMIN' 
                  ? (filteredUser ? `Tous les projets de ${filteredUser.prenom} ${filteredUser.nom}` : 'La liste de projets de tous les utilisateurs') 
                  : 'Votre liste de projets'}
              </h1>
              {error && <div className="bg-red-500 text-white p-2 rounded">{error}</div>}
              <div className="flex justify-between items-center mb-4">
                <input
                  type="text"
                  placeholder="Rechercher par Projet ou Titre"
                  className="flex-grow border p-2 rounded mr-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  onChange={handleSearchChange}
                />
                {user.role === 'ADMIN' && (
                  <button
                    onClick={toggleFilterModal}
                    className={`mr-4 p-2 ${selectedUserId !== null ? 'text-blue-500' : 'text-gray-500'} hover:text-blue-700`}
                  >
                    <i className="fas fa-shapes"></i>
                  </button>
                )}
                <button onClick={toggleModal} className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded text-2xl">
                  <i className="fas fa-plus"></i>
                </button>
              </div>
              <Modal isOpen={isFilterModalOpen} onClose={toggleFilterModal}>
                <div className="p-4">
                  <h2 className="text-lg font-medium mb-4">Filtrer par utilisateur</h2>
                  <select onChange={handleUserChange} className="form-select block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option value="">Sélectionnez un utilisateur</option>
                    <option value="all">Afficher tous les projets</option>
                    {users.map((user) => (
                      <option key={user.id} value={user.id}>{user.prenom} {user.nom}</option>
                    ))}
                  </select>
                </div>
              </Modal>
              <Modal isOpen={isModalOpen} onClose={toggleModal}>
                <CreateProject onClose={toggleModal} onProjectAdd={handleProjectAdd} />
              </Modal>
              <div className="mt-6 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead style={{ backgroundColor: '#E5E7EB' }}>
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-s font-semibold text-black uppercase tracking-wider">
                        Projet
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-s font-semibold text-black uppercase tracking-wider">
                        Titre
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-s font-semibold text-black uppercase tracking-wider">
                        Commentaire
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-s font-semibold text-black uppercase tracking-wider">
                        Propriétaire
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-s font-semibold text-black uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {filteredProjects.map((project, index) => {
                      const isAssignedProject = project.ID_Utilisateur !== user.id;
                      const isProjectOwner = project.ID_Utilisateur === user.id;

                      return (
                        <tr key={project.id}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}>
                          {editProjectId === project.id ? (
                            <>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <input
                                  type="text"
                                  name="NomDuProjet"
                                  value={editFormData.NomDuProjet}
                                  onChange={handleInputChange}
                                  className="border-2 border-gray-300 rounded-md w-full"
                                />
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <input
                                  type="text"
                                  name="Titre"
                                  value={editFormData.Titre}
                                  onChange={handleInputChange}
                                  className="border-2 border-gray-300 rounded-md w-full"
                                />
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <input
                                  type="text"
                                  name="Commentaire"
                                  value={editFormData.Commentaire}
                                  onChange={handleInputChange}
                                  className="border-2 border-gray-300 rounded-md w-full"
                                />
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {project.prenomProprietaire} {project.nomProprietaire}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                <button
                                  onClick={handleSaveClick}
                                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded mr-2"
                                >
                                  Enregistrer
                                </button>
                                <button
                                  onClick={handleCancelClick}
                                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded"
                                >
                                  Annuler
                                </button>
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <Link to={`/project-details/${project.id}`} className="text-black hover:text-blue-900 font-bold">{project.NomDuProjet}</Link>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">{project.Titre}</td>
                              <td className="px-6 py-4 whitespace-nowrap">{project.Commentaire}</td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {project.prenomProprietaire} {project.nomProprietaire}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                <button
                                  onClick={() => handleEditClick(project)}
                                  className={`py-1 px-2 rounded ml-4 ${
                                    hoveredColumn === index
                                      ? 'bg-blue-600 text-white hover:bg-blue-400'
                                      : 'bg-white text-white hover:bg-green-400 hover:text-white'
                                  }`}
                                >
                                  Edit
                                </button>
                                {(user.role === 'ADMIN' || isProjectOwner) && (
                                  <button
                                    onClick={() => confirmDelete(project.id, project.NomDuProjet)}
                                    className={`py-1 px-2 rounded ml-4 ${
                                      hoveredColumn === index
                                        ? 'bg-red-600 text-white hover:bg-red-400'
                                        : 'bg-white text-white hover:bg-green-400 hover:text-white'
                                    }`}
                                  >
                                    Supprimer
                                  </button>
                                )}
                                {user.role === 'ADMIN' && selectedUserId && project.ID_Utilisateur !== parseInt(selectedUserId) && (
                                  <button
                                    onClick={() => confirmUnassign(project.id, project.NomDuProjet, selectedUserId)}
                                    className={`py-1 px-2 rounded ml-4 ${
                                      hoveredColumn === index
                                        ? 'bg-yellow-600 text-white hover:bg-yellow-400'
                                        : 'bg-white text-white hover:bg-yellow-400 hover:text-white'
                                    }`}
                                  >
                                    Dissocier
                                  </button>
                                )}
                                {user.role !== 'ADMIN' && isAssignedProject && (
                                  <button
                                    onClick={() => confirmUnassign(project.id, project.NomDuProjet, user.id)}
                                    className={`py-1 px-2 rounded ml-4 ${
                                      hoveredColumn === index
                                        ? 'bg-yellow-600 text-white hover:bg-yellow-400'
                                        : 'bg-white text-white hover:bg-yellow-400 hover:text-white'
                                    }`}
                                  >
                                    Dissocier
                                  </button>
                                )}
                                {user.role === 'ADMIN' && (
                                  <button
                                    onClick={() => openAssignModal(project.id)}
                                    className={`py-1 px-2 rounded ml-4 ${
                                      hoveredColumn === index
                                        ? 'bg-green-600 text-white hover:bg-green-400'
                                        : 'bg-white text-white hover:text-white'
                                    }`}
                                  >
                                    Assigner
                                  </button>
                                )}
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <Modal isOpen={isAssignModalOpen} onClose={toggleAssignModal}>
                <div className="p-4">
                  <h2 className="text-lg font-medium mb-4">Assigner le projet à un utilisateur</h2>
                  <select onChange={handleUserChangeForAssignment} className="form-select block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option value="">Sélectionnez un utilisateur</option>
                    {users.map((user) => (
                      <option key={user.id} value={user.id}>{user.prenom} {user.nom}</option>
                    ))}
                  </select>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ViewProjects);
