import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/Login.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setEmail(e.target.value);
    setMessage('');
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      const response = await axios.post(`${apiBaseUrl}/users/forgot-password`, { email });
      setMessage(response.data.message);
    } catch (error) {
      if (error.response && error.response.data.errors) {
        setError(error.response.data.errors[0].msg);
      } else {
        setError('Une erreur est survenue. Veuillez réessayer plus tard.');
      }
    }
  };

  return (
    <div className="relative flex justify-center items-center min-h-screen">
      <img
        src="/fond_vert.png"
        alt="Bg"
        className="absolute inset-0 w-full h-full object-cover transform -scale-x-100"
      />
      <div className="relative bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <i
          className="fas fa-arrow-left absolute top-4 left-4 text-2xl cursor-pointer"
          onClick={() => navigate('/')}
          style={{ color: '#202244' }}
        ></i>
        <div className="mb-4 text-center">
          <img src="/logoPowerHouseNudge1.png" alt="PowerHouse Nudge Logo" className="h-12 mx-auto" />
          <h1 className="text-3xl font-bold mb-6">Mot de Passe Oublié</h1>
          <p>Entrez votre adresse email pour recevoir un mot de passe temporaire</p>
        </div>

        {message && <div className="bg-green-500 text-white p-2 rounded mb-4">{message}</div>}
        {error && <div className="bg-red-500 text-white p-2 rounded mb-4">{error}</div>}

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm mb-2" htmlFor="email">Email</label>
            <div className="relative">
              <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
                <i className="fas fa-envelope" aria-hidden="true"></i>
              </span>
              <input
                type="email"
                name="email"
                required
                className="placeholder-gray-400 block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Email"
                value={email}
                onChange={handleChange}
                aria-label="Email"
              />
            </div>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="group relative btn-login flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Envoyer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
