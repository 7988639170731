// CampaignContext.js
import React, { createContext, useState, useContext } from 'react';
import axios from 'axios';

const CampaignContext = createContext();

export const useCampaigns = () => useContext(CampaignContext);

export const CampaignProvider = ({ children }) => {
  const [campaigns, setCampaigns] = useState({});

  const fetchCampaigns = async (projectId) => {
    const token = localStorage.getItem('token');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const apiBaseUrl = process.env.REACT_APP_API_URL || '';
    try {
      const response = await axios.get(`${apiBaseUrl}/projets/${projectId}/details`, config);
      setCampaigns(prev => ({ ...prev, [projectId]: response.data }));
    } catch (error) {
      console.error('Erreur lors de la récupération des campagnes. Veuillez réessayer.');
    }
  };

  const addCampaign = (projectId, newCampaign) => {
    setCampaigns(prev => ({
      ...prev,
      [projectId]: [...(prev[projectId] || []), newCampaign]
    }));
  };

  return (
    <CampaignContext.Provider value={{ campaigns, addCampaign, fetchCampaigns }}>
      {children}
    </CampaignContext.Provider>
  );
};
