import React, { useState } from 'react';
import axios from 'axios';

const AddDocument = ({ projectId, detailId, onUploadSuccess }) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setError(''); // Clear error message when user selects a new file
  };

  const handleUpload = async () => {
    if (!file) {
      setError("Veuillez sélectionner un fichier à uploader.");
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    setUploading(true);
    setError('');

    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      const response = await axios.post(`${apiBaseUrl}/projets/${projectId}/details/${detailId}/documents/upload`, formData, config);

      setUploading(false);
      if (response.data) {
        onUploadSuccess(response.data, detailId); // Refresh the document list after a successful upload
        setFile(null); // Reset the file input after successful upload
      } else {
        setError("Une réponse inattendue a été reçue du serveur.");
      }
    } catch (error) {
      setError(`Une erreur s'est produite lors de l'upload du fichier: ${error.response?.data?.message || "Erreur non spécifiée"}`);
      setUploading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white shadow rounded">
      <input
        type="file"
        onChange={handleFileChange}
        className="block w-full text-sm text-gray-500
        file:mr-4 file:py-2 file:px-4
        file:rounded file:border-0
        file:text-sm file:font-semibold
        file:bg-blue-50 file:text-blue-700
        hover:file:bg-blue-100"
      />
      <button
        onClick={handleUpload}
        disabled={uploading}
        className={`mt-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${uploading ? 'bg-gray-300' : 'bg-blue-500 hover:bg-blue-700'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
      >
        {uploading ? 'Chargement...' : 'Uploader'}
      </button>
      {error && <div className="mt-2 text-sm text-red-600">{error}</div>}
    </div>
  );
};

export default AddDocument;
