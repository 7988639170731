import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Modal from './Modal';
import AddDashboard from './AddDashboard';
import EditDashboard from './EditDashboard';

const Dashboard = () => {
  const { id, detailId } = useParams();
  const [detail, setDetail] = useState(null);
  const [dashboards, setDashboards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState(null); 
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTypeDashboards, setSelectedTypeDashboards] = useState([]);
  const [hoveredDashboardId, setHoveredDashboardId] = useState(null);
  const navigate = useNavigate();

  const formatDate = (isoDate) => {
    if (!isoDate) return '';
    const date = new Date(isoDate);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  };

  const fetchDashboards = useCallback(async () => {
    const apiBaseUrl = process.env.REACT_APP_API_URL || '';
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${apiBaseUrl}/projets/${id}/details/${detailId}/dashboards`, config);
      setDashboards(response.data.length > 0 ? response.data.sort((a, b) => a.TypeDashboard.localeCompare(b.TypeDashboard)) : []);
      setLoading(false);
    } catch (err) {
      setDashboards([]); // On considère qu'il n'y a aucun dashboard si l'URL n'existe pas encore
      setLoading(false);
    }
  }, [id, detailId]);

  const fetchDetailProject = useCallback(async () => {
    const apiBaseUrl = process.env.REACT_APP_API_URL || '';
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${apiBaseUrl}/projets/${id}/details/${detailId}`, config);
      setDetail(response.data);
    } catch (err) {
      setError(`Impossible de charger le détail du projet: ${err.message}`);
      setLoading(false);
    }
  }, [id, detailId]);

  useEffect(() => {
    fetchDashboards();
    fetchDetailProject();
  }, [fetchDashboards, fetchDetailProject]);

  const toggleAddModal = () => setIsAddModalOpen(!isAddModalOpen);
  const toggleEditModal = () => setIsEditModalOpen(!isEditModalOpen);

  const handleTypeDashboardChange = (input) => {
    const type = input.target ? input.target.value : input;
    setSelectedTypeDashboards(prev =>
      prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]
    );
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleMouseEnter = (id) => {
    setHoveredDashboardId(id);
  };

  const handleMouseLeave = () => {
    setHoveredDashboardId(null);
  };

  const handleDelete = async (dashboardId, dashboardNom) => {
    const apiBaseUrl = process.env.REACT_APP_API_URL || '';
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer le dashboard "${dashboardNom}" ?`)) {
      try {
        await axios.delete(`${apiBaseUrl}/projets/${id}/details/${detailId}/dashboards/${dashboardId}`, config);
        fetchDashboards();
      } catch (err) {
        setError(`Erreur lors de la suppression du dashboard: ${err.message}`);
      }
    }
  };

  const handleEdit = (dashboard) => {
    setSelectedDashboard(dashboard);
    toggleEditModal();
  };

  if (loading) return <div>Chargement...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  const typeDashboards = [...new Set(dashboards.map(dashboard => dashboard.TypeDashboard))];

  const filteredDashboards = selectedTypeDashboards.length > 0 
    ? dashboards.filter(dashboard => selectedTypeDashboards.includes(dashboard.TypeDashboard)) 
    : dashboards;

  const groupedDashboards = filteredDashboards.reduce((acc, dashboard) => {
    const { TypeDashboard } = dashboard;
    if (!acc[TypeDashboard]) {
      acc[TypeDashboard] = [];
    }
    acc[TypeDashboard].push(dashboard);
    return acc;
  }, {});

  return (
    <div className="flex flex-row min-h-screen bg-gray-100">
      <div className="flex-grow bg-gray-50">
        <div className="w-full pt-6 bg-green-800" style={{ height: '25vh' }}>
          <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 mt-20">
            <div className="bg-white p-6 shadow rounded-lg mt-8 relative">
              <i
                className="fas fa-arrow-left absolute top-4 left-4 text-2xl cursor-pointer"
                onClick={() => navigate(-1)}
                style={{ color: '#202244' }}
              ></i>
              <h1 className="text-2xl font-bold text-gray-800 my-6">
                Dashboards Associés à la Campagne {detail && formatDate(detail.Campagne)}
              </h1>
              {error && <div className="bg-red-500 text-white p-2 rounded">{error}</div>}
              <div className="flex justify-between space-x-2.5 items-center mb-4">
                <input
                  type="text"
                  placeholder="Rechercher..."
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                  className="flex-grow border p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <select
                  className="bg-white text-black-900 py-1 px-2 rounded border cursor-pointer"
                  onChange={handleTypeDashboardChange}
                  value=""
                >
                  <option value="" disabled>Filtrer par type de dashboards</option>
                  {typeDashboards.map(type => (
                    <option 
                      key={type} 
                      value={type} 
                      style={{
                        backgroundColor: selectedTypeDashboards.includes(type) ? '#ffcccc' : 'initial',
                        color: selectedTypeDashboards.includes(type) ? 'black' : 'initial'
                      }}
                    >
                      {type}
                    </option>
                  ))}
                </select>

                <button onClick={toggleAddModal} className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded">
                  <i className="fas fa-plus"></i>
                </button>
              </div>
              <div className="space-x-2.5 mb-4">
                {selectedTypeDashboards.map(type => (
                  <span key={type} onClick={() => handleTypeDashboardChange(type)}
                    className="py-1.5 pl-4 pr-8 rounded bg-blue-600 text-white hover:bg-red-400 cursor-pointer relative">
                    {type}
                    <i className="fas fa-times-circle absolute right-0 top-0 mr-2 mt-2 text-white cursor-pointer"></i>
                  </span>
                ))}
              </div>
              <Modal isOpen={isAddModalOpen} onClose={toggleAddModal}>
                <AddDashboard onAddSuccess={() => {
                  setIsAddModalOpen(false);
                  fetchDashboards();
                }} />
              </Modal>
              <Modal isOpen={isEditModalOpen} onClose={toggleEditModal}>
                <EditDashboard 
                  dashboard={selectedDashboard} 
                  onEditSuccess={() => {
                    setIsEditModalOpen(false);
                    fetchDashboards();
                  }} 
                  onClose={toggleEditModal} 
                />
              </Modal>
              {Object.keys(groupedDashboards).length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                  {Object.keys(groupedDashboards).map(typeDashboard => (
                    <div key={typeDashboard} className="bg-white p-4 rounded-lg shadow">
                      <h2 className="text-xl text-center font-semibold mb-2">{typeDashboard}</h2>
                      <ul>
                        {groupedDashboards[typeDashboard].map(filteredDashboard => (
                          <li
                            key={filteredDashboard.id}
                            className="mb-1 bg-gray-100 py-3 px-2 rounded relative"
                            onMouseEnter={() => handleMouseEnter(filteredDashboard.id)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <a href={filteredDashboard.url_Dashboard} target="_blank" rel="noopener noreferrer" className="text-black hover:text-blue-700">
                              {filteredDashboard.nom}
                            </a>
                            {hoveredDashboardId === filteredDashboard.id && (
                              <>
                                <i
                                  className="fas fa-trash-alt absolute right-4 top-1/2 transform -translate-y-1/2 text-red-500 cursor-pointer"
                                  onClick={() => handleDelete(filteredDashboard.id, filteredDashboard.nom)}
                                ></i>
                                <i
                                  className="fas fa-edit absolute right-12 top-1/2 transform -translate-y-1/2 text-blue-500 cursor-pointer"
                                  onClick={() => handleEdit(filteredDashboard)}
                                ></i>
                              </>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-600">Aucun dashboard trouvé pour ce détail de projet. Utilisez le bouton "+" ci-dessus pour ajouter un nouveau dashboard.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Dashboard);