import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const Navbar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  if (!user) return null;

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setIsOpen(false);
  };

  return (
    <nav className="bg-white text-gray-800 h-14 flex items-center justify-between px-4 fixed top-0 left-0 right-0 z-50">
      <div className="flex items-center">
        <div className="flex items-center justify-center h-14">
          <img src="/logoPowerHouseNudge1.png" alt="PowerHouse Nudge Logo" className="h-12" />
        </div>
      </div>
      <div className="relative">
        <div onClick={toggleDropdown} className="cursor-pointer">
          <div className="w-10 h-10 rounded-full overflow-hidden border-2 border-gray-300 flex justify-center items-center">
            {user.profileImageUrl ? (
              <img src={user.profileImageUrl} alt="Profil" className="w-full h-full" />
            ) : (
              <i className="fas fa-user-circle fa-2x text-gray-600"></i>
            )}
          </div>
        </div>
        {isOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50">
            <button onClick={() => handleNavigation('/settings')} className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200">Paramètres</button>
            {user.role === 'ADMIN' && (
              <button onClick={() => handleNavigation('/admin')} className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200">Admin</button>
            )}
            <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200">Déconnexion</button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
